<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-06-27 22:02:53
 * @FilePath: \beilunchanye_fe_20211208\src\views\other.vue
 * @Description: 
-->
<template>
	<div class="home other">
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="other-left-wrap other-wrap" :class="{ animate__animated: true, animate__fadeInLeftBig: $store.state.showDataValue, animate__fadeOutLeftBig: !$store.state.showDataValue }">
			<div class="other-wrap-title-wrap">
				<div class="other-wrap-title">资产园区</div>
			</div>
			<div class="other-wrap-line-wrap other-wrap-line-wrap-top">
				<div class="other-wrap-line other-wrap-line-left"></div>
				<div class="other-wrap-line other-wrap-line-right"></div>
			</div>
			<div class="other-wrap-line-wrap other-wrap-line-wrap-bottom">
				<div class="other-wrap-line other-wrap-line-left"></div>
				<div class="other-wrap-line other-wrap-line-right"></div>
			</div>
			<div class="other-left-content">
				<swiper class="swiper" :options="swiperOption">
					<swiper-slide v-for="(item, index) in returnPackAllPage(parkList)" :key="index">
						<div class="other-item" v-for="(jtem, jndex) in item.list" :key="jndex">
							<div class="other-item-title" @click="onItemMap(jtem.chart.id - 1)">
								<div class="other-item-title-wrap">
									<div class="other-item-title-left">
										<div class="other-item-title-icon"></div>
										<div class="other-item-title-name">{{ jtem.overview.park_name }}</div>
									</div>
									<div class="other-item-title-right"></div>
								</div>
								<div class="other-item-title-line"></div>
							</div>
							<div class="other-item-content">
								<div class="other-item-content-data">
									<div class="other-item-content-data-chart">
										<div :id="['Chart' + jtem.chart.id]" :style="{ width: '85px', height: '85px' }"></div>
										<div class="other-item-content-data-chart-circle"><CountUp :delay="1000" :endVal="jtem.overview.proportion || 0" :options="{ decimalPlaces: decimalPlaces(jtem.overview.proportion), useGrouping: false }" />(%)</div>
										<div class="other-item-content-data-chart-light"></div>
									</div>
									<div class="other-item-content-data-data">
										<div class="other-item-content-data-data-item">
											<div class="other-item-content-data-data-item-icon"></div>
											<div class="other-item-content-data-data-item-data">
												可租赁面积
												<CountUp :delay="1000" :endVal="jtem.overview.full_rent_area || 0" :options="{ decimalPlaces: decimalPlaces(jtem.overview.full_rent_area), useGrouping: false }" />
												<span>m²</span>
											</div>
										</div>
										<div class="other-item-content-data-data-item">
											<div class="other-item-content-data-data-item-icon"></div>
											<div class="other-item-content-data-data-item-data">
												已租赁面积
												<CountUp :delay="1000" :endVal="jtem.overview.rent_area || 0" :options="{ decimalPlaces: decimalPlaces(jtem.overview.rent_area), useGrouping: false }" />
												<span>m²</span>
											</div>
										</div>
										<div class="other-item-content-data-data-item">
											<div class="other-item-content-data-data-item-icon"></div>
											<div class="other-item-content-data-data-item-data">
												未租赁面积
												<CountUp :delay="1000" :endVal="jtem.overview.not_rent_area || 0" :options="{ decimalPlaces: decimalPlaces(jtem.overview.not_rent_area), useGrouping: false }" />
												<span>m²</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
				<div class="swiper-pagination" slot="pagination"></div>
			</div>
		</div>
		<!-- 园区企业 -->
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="other-right-wrap other-wrap" :class="{ animate__animated: true, animate__fadeInRightBig: $store.state.showDataValue, animate__fadeOutRightBig: !$store.state.showDataValue }">
			<div class="other-wrap-title-wrap">
				<div class="other-wrap-title">园区企业</div>
			</div>
			<div class="other-wrap-line-wrap other-wrap-line-wrap-top">
				<div class="other-wrap-line other-wrap-line-left"></div>
				<div class="other-wrap-line other-wrap-line-right"></div>
			</div>
			<div class="other-wrap-line-wrap other-wrap-line-wrap-bottom">
				<div class="other-wrap-line other-wrap-line-left"></div>
				<div class="other-wrap-line other-wrap-line-right"></div>
			</div>
			<div class="other-right-content">
				<div class="other-right-content-data" v-if="companyList.length > 0">
					<div class="other-right-content-data-list">
						<div v-for="(item, index) in companyList" :key="index" class="other-right-content-data-item" :class="{ 'other-right-content-data-active': itemData && itemData.company_name == item.company_name }" @click="onItem(item, index)">
							<div class="other-right-content-data-item_title">
								<span>{{ indexNum(index + 1) }}</span
								>{{ item.park }}
							</div>
							<div class="other-right-content-data-item_title">
								{{ item.company_name }}
							</div>
						</div>
					</div>
				</div>
				<div class="other-right-content-data-list-nodata" v-else>当前暂无数据</div>
			</div>
		</div>
		<CompanyDetails
			name="other"
			:visible="visible"
			:data="itemData"
			:onClose="
				() => {
					itemData = null;
					visible = !visible;
				}
			"
		/>
	</div>
</template>

<script>
	import { getOtherData } from '@/api';
	import { getCompanyList } from '@/api/zgc';
	import { returnAllPageFunc } from '@/utils/utils';
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
	import CountUp from 'vue-countup-v2';
	import CompanyDetails from '@/components/companyDetails';
	import 'swiper/css/swiper.css';

	export default {
		name: 'Other',
		data() {
			return {
				parkList: [],
				companyList: [],
				visible: false,
				itemData: null,
				swiperOption: {
					autoplay: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				},
			};
		},
		computed: {
			returnPackAllPage() {
				return function (list) {
					return returnAllPageFunc(5, list);
				};
			},
			decimalPlaces() {
				return function (number) {
					if (number) {
						const num = number.toString();
						const index = num.indexOf('.') != -1 ? num.indexOf('.') + 1 : 0;
						return index && num.length - index;
					}
				};
			},
			indexNum() {
				return function (value) {
					return value >= 10 ? value : `0${value}`;
				};
			},
		},
		components: {
			CountUp,
			CompanyDetails,
			Swiper,
			SwiperSlide,
		},
		methods: {
			onItemMap(index) {
				if (this.parkList[index].camera.id) {
					const cameraFly = {
						funcName: 'cameraFly',
						param: this.parkList[index].camera,
					};
					const screenWrap = document.getElementById('screenWrap');
					screenWrap.contentWindow.postMessage(cameraFly, '*');
				} else {
					this.$message('当前资产暂不支持跳转');
				}
			},
			onItem(item) {
				this.itemData = item;
				this.visible = false;
				setTimeout(() => {
					this.visible = true;
				}, 100);
			},
			drawChart() {
				for (let i = 0; i < this.parkList.length; i++) {
					const chart = this.$echarts.init(document.getElementById(`Chart${this.parkList[i].chart.id}`));
					chart.setOption({
						color: ['#B8DBFF', '#3C4F67'],
						series: [
							{
								type: 'pie',
								radius: ['88%', '100%'],
								label: {
									show: false,
								},
								data: this.parkList[i].chart.data,
							},
						],
					});
				}
			},
			getData() {
				const ids = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
				for (let i = 0; i < ids.length; i++) {
					getCompanyList({
						type: ids[i],
						limit: 999,
					}).then(res => {
						console.log('获取企业列表数据', res);
						const { data } = res;
						data.map(item => {
							const company_industry = [];
							item.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
							item.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
							item.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null;
							item.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null;
							item.distribution = item.distribution ? JSON.parse(item.distribution) : [];
							item.company_industry = item.company_industry ? JSON.parse(item.company_industry) : [];
							item.company_industry.map(item => {
								if (item.children && item.children.length > 0) {
									item.children.map(jtem => {
										company_industry.push({
											value: jtem.industry_id,
											label: jtem.industry_name,
										});
									});
								}
							});
							item.company_industry = company_industry;
							item.grade = item.grade ? JSON.parse(item.grade) : [];
							item.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : [];
						});
						this.companyList = [...this.companyList, ...data];
					});
				}
			},
			getOtherData() {
				getOtherData().then(res => {
					const { data } = res;
					console.log('园区列表', data);
					const parksArr = [];
					const parksObj = {
						1: {
							camera: {
								id: 11,
								title: '丹棱SOHO',
								content: '丹棱SOHO（原中冠大厦）位于北京中关村核心区，丹棱街与海淀东三街交口，北邻中关村广场步行街，南朝海淀医院，东接新中关购物中心，西靠北京市海淀区人民法院。地处中关村商务区核心区域。',
							},
							chart: {
								id: 1,
								data: [100, 0],
							},
						},
						2: {
							camera: {
								id: 12,
								title: '钢管厂厂区',
								content: '北京市海淀区钢管厂有限公司成立于1981年01月01日，位于北京市海淀区温泉乡太舟坞，经营范围包括加工钢管、轴承钢、内燃机车特钢曲轴、汽车凸轮轴、半轴片，技术开发，加工特殊钢零件，制造、修理农业机械，制造、加工金属结构；租赁办公用房。',
							},
							chart: {
								id: 2,
								data: [100, 0],
							},
						},
						3: {
							camera: {
								id: 13,
								title: '常兴海广培训中心',
								content: '北京常兴海广培训中心，办公室地址位于中国的首都，政治、文化中心北京，北京 北京市昌平县小汤山镇常兴庄，我单位主要提供会议服务其中实物出资为90.0万元',
							},
							chart: {
								id: 3,
								data: [100, 0],
							},
						},
						5: {
							camera: {
								id: 15,
								title: '中科大厦B座',
								content: '项目位于北京市海淀区中关村大街，地理位置优越，交通便利。项目分AB两座，楼内多种户型的办公间可供选择，并可根据客户的要求自由分割，商务设施齐全，周边配套设施齐备，入驻企业可享受中关村科技园区的相关优惠政策。',
							},
							chart: {
								id: 4,
								data: [100, 0],
							},
						},
						7: {
							camera: {
								id: 17,
								title: '知春大厦',
								content: '社区主流健康向上，社区风气良好，邻里关系和谐。物业有保障，保安24小时值班。物业管理完善，住户素质高。是集商尝学校、幼儿园和社区文化娱乐设施为一体的超大规模绿色生态居住区。',
							},
							chart: {
								id: 5,
								data: [100, 0],
							},
						},
						8: {
							camera: {
								id: 18,
								title: '彩和坊',
								content: '彩和坊24号院，属区级文保单位，位于海淀区彩和坊南端，建于光绪年间，为清朝著名太监李莲英在海淀镇修建的三处宅院之一。',
							},
							chart: {
								id: 6,
								data: [100, 0],
							},
						},
						6: {
							camera: {
								id: 16,
								title: '领行国际',
								content: '领行国际位于北京市东城广渠门，由北京信茂房地产开发有限公司建成，总建筑面积96000，总占地面积12000，共计房屋613户，小区物业公司为北京北方丹龙物业管理有限公司。',
							},
							chart: {
								id: 7,
								data: [76, 24],
							},
						},
						9: {
							camera: {
								id: null,
								title: 'none',
								content: 'none',
							},
							chart: {
								id: 8,
								data: [57, 43],
							},
						},
						11: {
							camera: {
								id: 19,
								title: '商务在线',
								content: '锦绣大地农副产品批发市场是京西地区最大的农产品批发市场，农副产品供应覆盖了北京超过三分之一的市场需求，锦绣大地批发市场主要品种覆盖了北京市的大中小农副产品市场和超市、饭店、部队和企事业单位食堂',
							},
							chart: {
								id: 9,
								data: [100, 0],
							},
						},
						10: {
							camera: {
								id: 20,
								title: '大地物流港',
								content: '北京锦绣大地物流港北京市锦绣大地物流港是于05年竣工的钢架玻璃结构的高级商业楼，14万平米基本满租，现阶段顶层8层写字楼招租，使用面积约5000平米，配套设施齐全，有3000个停车位的大型地上停车场及周边的大型医院学校和银行，地于西4环，上风上水，交通便利。',
							},
							chart: {
								id: 10,
								data: [100, 0],
							},
						},
						4: {
							camera: {
								id: 14,
								title: '凯文大厦',
								content: '北京写字楼租售中心隶属于北京恒量房地产经纪有限公司。自1997年成立，随着十几年不断的发展及完善，已成为一个信息全、覆盖面广、专业度高的地产服务机构。',
							},
							chart: {
								id: 11,
								data: [0.45, 99.55],
							},
						},
						12: {
							chart: {
								id: 12,
								data: [0.45, 99.55],
							},
						},
						13: {
							chart: {
								id: 13,
								data: [49.5, 50.5],
							},
						},
						14: {
							chart: {
								id: 14,
								data: [54.61, 45.39],
							},
						},
						15: {
							chart: {
								id: 15,
								data: [100, 0],
							},
						},
						16: {
							chart: {
								id: 16,
								data: [7.79, 92.21],
							},
						},
						17: {
							chart: {
								id: 17,
								data: [18.23, 81.77],
							},
						},
						18: {
							chart: {
								id: 18,
								data: [0, 100],
							},
						},
					};
					for (let key in parksObj) {
						data.map(item => {
							if (item.id == key) {
								item.proportion = Number(((item.rent_area / item.full_rent_area) * 100).toFixed(2));
								parksArr.push({
									...parksObj[key],
									overview: item,
									// chart: {
									// 	data: [item.proportion, 100 - item.proportion],
									// },
								});
							}
						});
					}
					this.parkList = parksArr;
					this.$nextTick(() => {
						this.drawChart();
					});
				});
			},
		},
		mounted() {
			this.getData();
			this.getOtherData();
		},
		beforeDestory() {},
	};
</script>

<style lang="less">
	.other {
		.other-wrap {
			z-index: 1003;
			position: absolute;
			width: 420px;
			height: 934px;
			bottom: 30px;
			border: 1px solid rgba(191, 225, 252, 0.3);
			border-top-width: 0;

			.other-wrap-title-wrap {
				position: absolute;
				top: -3px;
				left: 50%;
				width: 418px;
				height: 40px;
				transform: translateX(-50%);
				background-size: 100%;
				background-repeat: no-repeat;
				background-image: url(../assets/images/other/wrap-header.png);

				.other-wrap-title {
					position: absolute;
					left: 50%;
					top: -50%;
					font-size: 24px;
					font-weight: 500;
					color: #e4eaff;
					transform: translateX(-50%);
				}
			}

			.other-wrap-line-wrap {
				position: absolute;
				width: 100%;
				height: 20px;

				.other-wrap-line {
					position: absolute;
					width: 20px;
					height: 20px;
				}

				.other-wrap-line-left {
					left: -1px;

					&::before {
						left: 0;
					}
				}

				.other-wrap-line-right {
					right: -1px;

					&::before {
						right: 0;
					}
				}

				.other-wrap-line-left::after,
				.other-wrap-line-left::before,
				.other-wrap-line-right::after,
				.other-wrap-line-right::before {
					content: '';
					position: absolute;
					display: inline-block;
					background-color: #b4e4ff;
				}

				.other-wrap-line-left::after,
				.other-wrap-line-right::after {
					width: 100%;
					height: 2px;
				}

				.other-wrap-line-left::before,
				.other-wrap-line-right::before {
					width: 2px;
					height: 100%;
				}
			}

			.other-wrap-line-wrap-top {
				top: -1px;
			}

			.other-wrap-line-wrap-bottom {
				bottom: -1px;

				.other-wrap-line-left::after {
					bottom: 0;
				}

				.other-wrap-line-right::after {
					bottom: 0;
				}
			}
		}

		.other-left-wrap {
			left: 30px;

			.other-left-content {
				position: relative;
				z-index: 10;
				top: 38px;
				height: 905px;
				padding: 0 10px;

				.other-item {
					margin-bottom: 10px;
					background: rgba(5, 12, 26, 0.5);

					&:last-child {
						margin-bottom: 0;
					}

					.other-item-title {
						cursor: pointer;
						position: relative;
						background: linear-gradient(90deg, rgba(5, 12, 26, 0.5) 0%, rgba(77, 166, 239, 0.5) 100%);
						user-select: none;

						&:active {
							opacity: 0.5;
						}

						.other-item-title-wrap {
							height: 30px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.other-item-title-left {
								display: flex;
								align-items: center;

								.other-item-title-icon {
									width: 32px;
									height: 32px;
									background-size: 100%;
									background-repeat: no-repeat;
									background-image: url(../assets/images/other/item-title-icon-l.png);
								}

								.other-item-title-name {
									font-size: 18px;
									font-weight: bold;
									color: #ffffff;
									margin-left: 8px;
									margin-top: -4px;
								}
							}

							.other-item-title-right {
								width: 16px;
								height: 16px;
								margin-right: 10px;
								background-size: 100%;
								background-repeat: no-repeat;
								background-image: url(../assets/images/other/item-title-icon-r.png);
							}
						}

						.other-item-title-line {
							position: absolute;
							width: 370px;
							height: 1px;
							right: 0;
							bottom: 0;
							background: linear-gradient(90deg, #39a7df 0%, #6b8db4 100%);
						}
					}

					.other-item-content {
						padding: 15px;

						.other-item-content-data {
							display: flex;
							align-items: center;

							.other-item-content-data-chart {
								position: relative;

								.other-item-content-data-chart-circle {
									position: absolute;
									display: flex;
									align-items: center;
									justify-content: center;
									width: 68px;
									height: 68px;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -49%);
									background-size: 100%;
									background-repeat: no-repeat;
									background-image: url(../assets/images/other/item-circle.png);
									font-size: 12px;
									color: rgba(184, 219, 255, 0.6);

									span {
										font-weight: bold;
										font-family: 'bigdsfont';
										font-size: 26px;
										color: #b8dbff;
									}
								}

								.other-item-content-data-chart-light {
									position: absolute;
									width: 15px;
									height: 22px;
									left: 50%;
									top: -7.5px;
									transform: translateX(-50%);
									background-size: 100%;
									background-repeat: no-repeat;
									background-image: url(../assets/images/other/item-light.png);
								}
							}

							.other-item-content-data-data {
								margin-left: 32px;

								.other-item-content-data-data-item {
									position: relative;
									display: flex;
									align-items: center;

									&:nth-child(1) {
										margin-bottom: 15px;

										.other-item-content-data-data-item-icon {
											background-image: url(../assets/images/other/item-icon.png);

											&::before {
												display: none;
											}
										}

										.other-item-content-data-data-item-data {
											font-size: 16px;
											font-weight: bold;

											span {
												color: #44ddc2;

												&:nth-child(1) {
													font-size: 24px;
												}
											}
										}
									}

									&:nth-child(2) {
										.other-item-content-data-data-item-icon {
											&::before {
												background: #b8dbff;
											}
										}

										.other-item-content-data-data-item-data {
											span {
												color: #b8dbff;
											}
										}
									}

									&:nth-child(3) {
										.other-item-content-data-data-item-icon {
											&::before {
												background: #75a2d0;
											}
										}

										.other-item-content-data-data-item-data {
											span {
												color: #75a2d0;
											}
										}
									}

									&:last-child {
										margin-bottom: 0;
									}

									.other-item-content-data-data-item-icon {
										display: flex;
										align-items: center;
										justify-content: center;
										width: 30px;
										height: 30px;
										margin-right: 6px;
										background-size: 100%;
										background-repeat: no-repeat;

										&::before {
											content: '';
											width: 7px;
											height: 7px;
										}
									}

									.other-item-content-data-data-item-data {
										display: flex;
										align-items: center;
										font-size: 14px;
										color: rgba(255, 255, 255, 0.8);

										span {
											&:nth-child(1) {
												font-size: 22px;
												margin-left: 10px;
												font-weight: bold;
												font-family: 'bigdsfont';
											}

											&:nth-child(2) {
												font-size: 16px;
											}
										}
									}
								}
							}
						}
					}
				}

				.swiper-pagination {
					position: relative;

					.swiper-pagination-bullet {
						width: 36px;
						height: 7px;
						margin-right: 24px;
						background: #b8dbff;
						opacity: 0.6;
						border-radius: 3px;

						&:last-child {
							margin-right: 0;
						}
					}

					.swiper-pagination-bullet-active {
						opacity: 1;
					}
				}
			}
		}

		.other-right-wrap {
			right: 30px;
			background: rgba(5, 12, 26, 0.3);

			.other-right-content {
				z-index: 10;
				position: relative;
				top: 28px;
				max-height: 905px;
				overflow-y: scroll;

				&::-webkit-scrollbar {
					display: none;
				}

				.other-right-content-data {
					align-items: center;
					justify-content: center;
					margin: 0;

					.other-right-content-data-list {
						margin: 12px;

						.other-right-content-data-item {
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding: 12px 8px;
							margin-bottom: 18px;
							background: rgba(77, 166, 239, 0.3);

							.other-right-content-data-item_title {
								text-align: left;
								font-size: 16px;
								font-weight: 400;
								color: #e4eaff;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;

								span {
									margin-right: 14px;
								}

								&:nth-child(1) {
									width: 40%;
								}

								&:nth-child(2) {
									width: 55%;
								}
							}

							&:active {
								opacity: 0.5;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}

						.other-right-content-data-active {
							background: rgba(46, 116, 142, 0.2);
							border-top: 1px solid #1e6284;
							border-bottom: 1px solid #1e6284;

							.other-right-content-data-item_title {
								font-size: 18px;
								font-weight: bold;
								color: #ffffff;
							}
						}
					}
				}

				.other-right-content-data-list-nodata {
					height: 905px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
</style>
